@use "header";
@use "footer";

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  font-family: "Raleway", serif;
  font-size: 10px;
  color: #333;
}

body {
  background-color: #fffffc;
  p {
    font-size: 1.75rem;

    a {
      color: #003399;
      font-weight: 800;
    }
  }
}
.wrapper {
  box-sizing: border-box;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  h1 {
    text-align: center;
    font-size: 4rem;
    font-weight: 900;
  }
}

.home-container {
  display: flex;
  .headshot {
    margin: 2rem 1rem 1rem 1rem;
    img {
      max-width: 600;
    }
  }

  .content {
    flex-grow: 1;

    h1 {
      text-align: center;
    }

    p {
      margin-block: 1rem;
      margin: 5rem;
    }
  }
}

@media (max-width: 800px) {
  .home-container {
    flex-direction: column-reverse;
    .headshot {
      display: flex;
      justify-content: center;
      img {
        max-width: 300;
      }
    }
  }
}
