header {
  color: #fff;
  background-color: #039;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  display: flex;
}

header ul {
  margin: 1rem;
  list-style: none;
}

header ul li {
  margin: .5rem;
  display: inline;
}

header ul li a {
  color: #fff;
  font-size: 2rem;
  text-decoration: none;
}

header ul li a:hover, header ul li a:focus {
  text-decoration: underline;
}

@media (width <= 600px) {
  header {
    flex-direction: column;
    align-items: center;
  }

  header ul {
    padding: 0;
  }
}

footer {
  color: #fff;
  background-color: #039;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  font-size: 1.2rem;
  display: flex;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  color: #333;
  font-family: Raleway, serif;
  font-size: 10px;
}

body {
  background-color: #fffffc;
}

body p {
  font-size: 1.75rem;
}

body p a {
  color: #039;
  font-weight: 800;
}

.wrapper {
  box-sizing: border-box;
  flex-direction: column;
  min-height: 100%;
  display: flex;
}

main {
  flex-grow: 1;
}

main h1 {
  text-align: center;
  font-size: 4rem;
  font-weight: 900;
}

.home-container {
  display: flex;
}

.home-container .headshot {
  margin: 2rem 1rem 1rem;
}

.home-container .headshot img {
  max-width: 600px;
}

.home-container .content {
  flex-grow: 1;
}

.home-container .content h1 {
  text-align: center;
}

.home-container .content p {
  margin-block: 1rem;
  margin: 5rem;
}

@media (width <= 800px) {
  .home-container {
    flex-direction: column-reverse;
  }

  .home-container .headshot {
    justify-content: center;
    display: flex;
  }

  .home-container .headshot img {
    max-width: 300px;
  }
}
/*# sourceMappingURL=index.417d5ff8.css.map */
