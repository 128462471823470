header {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #003399;
  color: #fff;
  justify-content: space-between;
  align-items: baseline;
  flex-grow: 0;
  flex-shrink: 0;

  ul {
    list-style: none;
    margin: 1rem;
    li {
      display: inline;
      margin: 0.5rem;
      a {
        color: #fff;
        text-decoration: none;
        font-size: 2rem;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;

    ul{
      padding: 0;
    }
  }
}
